// import { graphql, useStaticQuery, Link } from "gatsby";
// import React, { useState } from "react";
import React from "react";

function Footer() {
  // const [isExpanded, toggleExpansion] = useState(false);

  return (
    <footer className="bg-blue-900">
      <div className="flex flex-wrap justify-between max-w-4xl mx-auto py-8 ">
        <div className="text-sm ">
          <p className="text-white">
            <b>By Robotic Pty Ltd </b><br /><br />
            <p className="text-xs">ABN: 48 657 378 642 <br />
              ACN: 657 378 642</p>
          </p>
        </div>

        <div className="text-sm">
          <p className="text-white text-xs">
            Melbourne, Victoria, Australia
          </p>
        </div>
      </div >
    </footer>
  );
}

export default Footer;

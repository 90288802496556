import React from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax"

import Layout from "../components/layout";
import SEO from "../components/seo";

import HeroPage from "./hero";
import AboutPage from "./about"
import ContactPage from "./contact"
import Header from "../components/header"
import Footer from "../components/footer";

import "../styles/global.css";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`By Robotic`, `ByRobotic`, `Robot`, `Robotic Fabrication`, `Robotics`]}
        title="By Robotic Pty Ltd"
      />

      <Parallax pages={3} style={{ top: '0', left: '0' }}>


        {/* Sections/Pages */}

        <ParallaxLayer offset={0} speed={0.5} classname="" >
          <Header />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={1} classname="">
          <HeroPage />
        </ParallaxLayer>

        {/* background images */}
        <ParallaxLayer offset={1} speed={0} className="bg-rose-400" />

        <ParallaxLayer offset={1} speed={1} classname="">
          <AboutPage />
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={1} className="">
          <div className="grid grid-cols-1 h-full">
            <ContactPage className="" />
            <Footer className="" />
          </div>
        </ParallaxLayer>

      </Parallax>

    </Layout >
  );
}

export default IndexPage;
